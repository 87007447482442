import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withKeys as _withKeys, withModifiers as _withModifiers, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pic-text-display" }
const _hoisted_2 = { style: {"margin-right":"6px"} }
const _hoisted_3 = { style: {"margin-right":"8px"} }
const _hoisted_4 = { style: {"margin-right":"8px"} }
const _hoisted_5 = {
  key: 0,
  class: "gray-background"
}
const _hoisted_6 = {
  key: 0,
  class: "status-dot green"
}
const _hoisted_7 = {
  key: 1,
  class: "status-dot red"
}
const _hoisted_8 = {
  key: 0,
  class: "status-dot green"
}
const _hoisted_9 = {
  key: 1,
  class: "status-dot red"
}

import {useI18n} from "vue-i18n" // 导入国际化插件
import {autoHeight, formatDate, formatFileSize, showLoading, showMessageBox, showNotification} from "@/services/tools";
import {ElTable, FormInstance} from "element-plus";
import {computed, onMounted, reactive, ref} from 'vue';
import {useFileStore} from "@/stores/fileStore";
import TreeList from "@/components/TreeList.vue";
import {KnowledgeFile} from "@/types/KnowledgeFile";
import selectTreeList from "@/components/selectTreeList.vue";
import UploadKnowledgeFile from "@/components/UploadKnowledgeFile.vue";


// 使用国际化功能

export default /*@__PURE__*/_defineComponent({
  __name: 'file_lib_m',
  setup(__props) {

const {t} = useI18n();

// 定义响应式变量
const treeListRef = ref<InstanceType<typeof TreeList>>(null);
const showDialog = ref<boolean>(false);
const showUploadDialog = ref<boolean>(false);
const fileModifyForm = ref<FormInstance>() // 模板表单实例
const cur_node_id = ref(-1);  // 当前选中的节点ID
const currentPage = ref(1);  // 当前页码
const pageSize = ref(10);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const tableData = ref<KnowledgeFile[]>([]);  // 表格数据
const tableHeight = autoHeight(200);  // 表格高度自适应
const multipleSelection = ref<KnowledgeFile[]>([]);  // 多选用户
const fileStore = useFileStore();  // 使用用户存储
const fileTable = ref<InstanceType<typeof ElTable> | null>(null);  // 表格实例引用
const cur_File = ref<KnowledgeFile | null>(null);  // 当前选中的模型
const modifyFileInfo = ref<boolean>(false);
const errorMessage = ref<string>('') // 新模板信息提示
const fileIds = ref<number[]>([]);
const options = ref<any[]>([
  {value: "-1", label: "全部"},
  {value: "0", label: "进行中"},
  {value: "1", label: "已完成"}]);

const searchData = reactive({
  keyword: '',  // 搜索关键字
  all: true,
  text: null,
  vectorization: null
});

// 表单验证规则
const fileRules = computed(() => ({
  name: [{required: true, max: 200, message: t('knowledge.name-check'), trigger: 'blur'}], // 名称验证规则
}))

const handleSelectionChange = (val: KnowledgeFile[]) => {
  multipleSelection.value = val;
};

const searchFiles = async (searchData: any) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, 1, pageSize.value);
    tableData.value = fileStore.knowledgeFileList
  } finally {
    loading.close();
  }
};

const saveFile = async (formEl: FormInstance | undefined) => {
  if (!formEl) return

  try {
    const valid = await formEl.validate() // 验证表单

    if (valid && cur_File.value) {
      if (cur_File.value.text_content == "") {
        cur_File.value.convert_text = false
        cur_File.value.convert_text_datetime = null
      } else {
        cur_File.value.convert_text = true
        cur_File.value.convert_text_datetime = new Date()
      }
      await fileStore.createOrUpdateFile(cur_File.value, null)
      //清空PromptData数据
      modifyFileInfo.value = false
      cur_File.value.name = ""
      cur_File.value.text_content = ""
      cur_File.value.version = 1
      errorMessage.value = ""
      showNotification('success', t('message.success'), t('knowledge.modify-success-info')) // 显示成功通知
      await handleCurrentChange(currentPage.value)

    }
  } catch (error: any) {
    modifyFileInfo.value = true
    errorMessage.value = ""
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'InvalidInput':
        errorMessage.value = t('message.no-empty');
        break;
      case 'MissingFileID':
        errorMessage.value = t('knowledge.fileid-no-exist') // 显示用户名已存在提示
        break;
      case "NotFoundFile":
        errorMessage.value = t('knowledge.file-no-exist') // 显示用户名已存在提示
        break;
      case 'null':
        break;
      default:
        console.log(error.response.data)
        showNotification('error', '未知错误', t('user.unknown-error'));
        break;
    }
  }
}

/**
 * 处理每页显示条目数变化
 * @param {number} val - 新的每页显示条目数
 */
const handleSizeChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, currentPage.value, val);
    pageSize.value = val;
    tableData.value = fileStore.knowledgeFileList;
  } finally {
    loading.close();
  }
};

const handleCurrentChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, val, pageSize.value);
    currentPage.value = val;
    tableData.value = fileStore.knowledgeFileList;
  } finally {
    loading.close();
  }
};

const handleSelectedNodeChange = async (node: any) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    cur_node_id.value = node.data.id;
    searchData.all = false;
    totalItems.value = await fileStore.getFiles(searchData, cur_node_id.value, 1, pageSize.value);
    currentPage.value = 1;
    tableData.value = fileStore.knowledgeFileList;
  } finally {
    loading.close();
  }
};

const refresh_list_tree = () => {
  handleCurrentChange(currentPage.value);
  treeListRef.value.refreshTree();
}

const show_upload_dialog = async () => {
  try {
    if (cur_node_id.value > 0) {
      showUploadDialog.value = true
    } else {
      await showMessageBox(t, t('message.upload'), t('knowledge.select-node'));
    }
  } catch (error) {
    console.log(error)
  }
}

const moveFiles = async () => {
  try {
    // 检查是否有选中的用户
    if (multipleSelection.value.length === 0) {
      await showMessageBox(t, t('knowledge.move-title'), t('message.del-last-one'));
      return;
    }
    fileIds.value.length = 0
    // 如果传入的行号有效添加到 fileIds
    fileIds.value.push(...multipleSelection.value.map(file => file.file_id!));
    showDialog.value = true
  } catch (error) {
    return;
  }
};


const deleteFiles = async (cur_row: number) => {
  const fileIds: number[] = [];

  try {
    // 检查是否有选中的用户
    if (cur_row === -1 && multipleSelection.value.length === 0) {
      await showMessageBox(t, t('message.del-title'), t('message.del-last-one'));
      return;
    }
  } catch (error) {
    return;
  }

  // 如果传入的行号有效添加到 fileIds
  if (cur_row !== -1) {
    fileIds.push(cur_row);
  } else {
    fileIds.push(...multipleSelection.value.map(file => file.file_id!));
  }

  // 执行删除操作
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    // 确认删除操作
    await showMessageBox(t, t('message.del-title'), t('message.del-conform'), false);

    await fileStore.deleteFiles(fileIds);
    // 处理删除结果
    tableData.value = tableData.value.filter(item =>
        !fileIds.includes(item.file_id!)
    );
    totalItems.value = totalItems.value - fileIds.length
    showNotification("success", t('message.del-success-title'), t('knowledge.del-file-success', {count: fileIds.length}));

    loading.close();
  } catch (error: any) {
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'UserAlreadyHasTask':
        showNotification('error', t('message.del-title'), "等待前一个删除任务完成再进行删除");
        break;
      case 'InvalidInput':
        showNotification("warning", t('message.del-title'), t('message.del-last-one'))
        break;
      case 'null':
        break;
      default:
        showNotification('error', '未知状态', error.response.data.detail);
        break;
    }
  } finally {
    loading.close();
  }
};

const show_dialog = async (file_data: KnowledgeFile | null) => {
  cur_File.value = JSON.parse(JSON.stringify(file_data));
  errorMessage.value = ""
  modifyFileInfo.value = true;
};

const cancelFile = async () => {
  errorMessage.value = ""
  modifyFileInfo.value = false;
  showDialog.value = false;
};

// 组件挂载后执行的操作
onMounted(async () => {
  const ps_key = localStorage.getItem("file_page_size");
  searchData.text = options.value[0].value;
  searchData.vectorization = options.value[0].value;
  searchData.all = true;

  if (ps_key) {
    pageSize.value = Number(ps_key);
  }
  await handleCurrentChange(1);  // 初始化加载第一页数据
});


return (_ctx: any,_cache: any) => {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button_group = _resolveComponent("el-button-group")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { class: "top-row" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_form, {
            ref: "searchForm",
            model: searchData,
            inline: true,
            class: "search-form",
            onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                prop: "searchAll",
                class: "top-input"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)("knowledge.all")), 1),
                  _createVNode(_component_el_switch, {
                    modelValue: searchData.all,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchData.all) = $event)),
                    "inline-prompt": "",
                    "active-text": _ctx.$t('message.yes'),
                    "inactive-text": _ctx.$t('message.no')
                  }, null, 8, ["modelValue", "active-text", "inactive-text"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_divider, {
                direction: "vertical",
                class: "set-color"
              }),
              _createVNode(_component_el_form_item, {
                prop: "search_text",
                class: "top-input"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('knowledge.text-date')), 1),
                  _createVNode(_component_el_select, {
                    modelValue: searchData.text,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchData.text) = $event)),
                    placeholder: _ctx.$t('knowledge.text-date'),
                    style: {"width":"120px"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.value,
                          label: item.label,
                          value: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_divider, {
                direction: "vertical",
                class: "set-color"
              }),
              _createVNode(_component_el_form_item, {
                prop: "search_vectorization",
                class: "top-input"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('knowledge.vectorization-date')), 1),
                  _createVNode(_component_el_select, {
                    modelValue: searchData.vectorization,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((searchData.vectorization) = $event)),
                    placeholder: _ctx.$t('knowledge.vectorization-date'),
                    style: {"width":"120px"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.value,
                          label: item.label,
                          value: item.value
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_divider, {
                direction: "vertical",
                class: "set-color"
              }),
              _createVNode(_component_el_form_item, {
                prop: "searchKey",
                class: "top-input"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: searchData.keyword,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((searchData.keyword) = $event)),
                    placeholder: _ctx.$t('user.SearchKey'),
                    onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (searchFiles(searchData)), ["enter"])),
                    style: {"width":"400px"},
                    clearable: ""
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_button, {
                type: "primary",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (searchFiles(searchData))),
                circle: "",
                plain: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass" })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"])
        ]),
        _createVNode(_component_el_button_group, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: show_upload_dialog,
              round: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "upload",
                  class: "icon-text-button"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('message.upload')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: moveFiles,
              round: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "arrow-right-arrow-left",
                  class: "icon-text-button"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('message.move')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "danger",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (deleteFiles(-1))),
              round: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, {
                  icon: "trash-can",
                  class: "icon-text-button"
                }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('message.delete')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_divider)
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, {
      gutter: 20,
      style: {"height":"auto"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 8 }, {
          default: _withCtx(() => [
            _createVNode(TreeList, {
              ref_key: "treeListRef",
              ref: treeListRef,
              treeName: "file",
              onSelectedNodeChanged: handleSelectedNodeChange
            }, null, 512)
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 16 }, {
          default: _withCtx(() => [
            _createVNode(_unref(ElTable), {
              ref_key: "fileTable",
              ref: fileTable,
              data: tableData.value,
              "highlight-current-row": "",
              height: _unref(tableHeight),
              "default-sort": { prop: 'create_datetime', order: 'descending' },
              onSelectionChange: handleSelectionChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  type: "expand",
                  width: "30"
                }, {
                  default: _withCtx(({ row }) => [
                    (row.text_content)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_el_text, { class: "title_text" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('knowledge.file-text')), 1)
                            ]),
                            _: 1
                          }),
                          _cache[19] || (_cache[19] = _createElementVNode("br", null, null, -1)),
                          _createVNode(_component_el_text, {
                            class: "multi-line-text",
                            "line-clamp": "8"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(row.text_content), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  fixed: "left",
                  type: "selection",
                  width: "30"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "name",
                  label: _ctx.$t('knowledge.file-name'),
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createVNode(_component_el_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(row.name), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1)),
                    _createVNode(_component_el_text, {
                      size: "small",
                      type: "info",
                      style: {"white-space":"nowrap"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("MD5: " + _toDisplayString(row.hash), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "version",
                  label: _ctx.$t('knowledge.file-version'),
                  width: "80",
                  align: "center",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createVNode(_component_el_text, {
                      size: "default",
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("V " + _toDisplayString(String(row.version).padStart(2, '0')), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "size",
                  label: _ctx.$t('knowledge.file-size'),
                  width: "80",
                  align: "center",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString(_unref(formatFileSize)(row.size)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "convert_text_datetime",
                  label: _ctx.$t('knowledge.text-date'),
                  width: "130",
                  align: "left",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createElementVNode("span", null, [
                      (row.convert_text)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6))
                        : (_openBlock(), _createElementBlock("span", _hoisted_7))
                    ]),
                    _createTextVNode(" " + _toDisplayString(_unref(formatDate)(row.convert_text_datetime, 'MM-dd HH:mm')), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "convert_vectorization_datetime",
                  label: _ctx.$t('knowledge.vectorization-date'),
                  width: "130",
                  align: "left",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createElementVNode("span", null, [
                      (row.convert_vectorization)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8))
                        : (_openBlock(), _createElementBlock("span", _hoisted_9))
                    ]),
                    _createTextVNode(" " + _toDisplayString(_unref(formatDate)(row.convert_vectorization_datetime, 'MM-dd HH:mm')), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "create_datetime",
                  label: _ctx.$t('knowledge.upload-date'),
                  width: "150",
                  align: "center",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString(_unref(formatDate)(row.create_datetime, 'yyyy-MM-dd HH:mm')), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  fixed: "right",
                  label: _ctx.$t('message.action'),
                  width: "90",
                  align: "center"
                }, {
                  default: _withCtx(({ row }) => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      size: "small",
                      circle: "",
                      plain: "",
                      onClick: ($event: any) => (show_dialog(row))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "pen" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"]),
                    _createVNode(_component_el_button, {
                      type: "danger",
                      size: "small",
                      onClick: ($event: any) => (deleteFiles(row.file_id)),
                      circle: "",
                      plain: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "trash-can" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["data", "height"]),
            _createVNode(_component_el_pagination, {
              "current-page": currentPage.value,
              "onUpdate:currentPage": _cache[8] || (_cache[8] = ($event: any) => ((currentPage).value = $event)),
              "page-size": pageSize.value,
              "onUpdate:pageSize": _cache[9] || (_cache[9] = ($event: any) => ((pageSize).value = $event)),
              "page-sizes": [20, 50,100,500],
              layout: "total, sizes, prev, pager, next, jumper",
              total: totalItems.value,
              onSizeChange: _cache[10] || (_cache[10] = ($event: any) => (handleSizeChange(pageSize.value))),
              onCurrentChange: _cache[11] || (_cache[11] = ($event: any) => (handleCurrentChange(currentPage.value)))
            }, null, 8, ["current-page", "page-size", "total"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_dialog, {
      modelValue: modifyFileInfo.value,
      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((modifyFileInfo).value = $event)),
      onClose: cancelFile,
      title: _ctx.$t('knowledge.modify-file-title'),
      width: "800px",
      "align-center": "",
      center: "",
      draggable: "",
      "close-on-click-modal": false
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[15] || (_cache[15] = ($event: any) => (saveFile(fileModifyForm.value)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: "check",
                class: "icon-text-button"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('message.save')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "danger",
            onClick: cancelFile,
            plain: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, {
                icon: "xmark",
                class: "icon-text-button"
              }),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('message.cancel')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        (errorMessage.value)
          ? (_openBlock(), _createBlock(_component_el_alert, {
              key: 0,
              type: "warning",
              description: errorMessage.value,
              closable: false,
              "show-icon": "",
              effect: 'light',
              class: "error_alert"
            }, null, 8, ["description"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_form, {
          ref_key: "fileModifyForm",
          ref: fileModifyForm,
          model: cur_File.value,
          rules: fileRules.value,
          "label-width": "80px",
          "label-position": 'right'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, { gutter: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 16 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('knowledge.file-name'),
                      prop: "name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: cur_File.value.name,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((cur_File.value.name) = $event)),
                          placeholder: _ctx.$t('knowledge.file-name-tip'),
                          clearable: "",
                          "show-word-limit": "",
                          maxlength: "200"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('knowledge.file-version'),
                      prop: "version",
                      class: "item"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input_number, {
                          modelValue: cur_File.value.version,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((cur_File.value.version) = $event)),
                          min: 1,
                          max: 99
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('knowledge.file-text'),
                      prop: "text_content",
                      class: "item"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: cur_File.value.text_content,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((cur_File.value.text_content) = $event)),
                          placeholder: _ctx.$t('knowledge.file-text-tip'),
                          rows: 10,
                          type: "textarea"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(selectTreeList, {
      modelValue: showDialog.value,
      "onUpdate:isShow": _cache[17] || (_cache[17] = ($event: any) => (showDialog.value = $event)),
      onRefreshList: refresh_list_tree,
      "tree-name": "file",
      file_ids: fileIds.value
    }, null, 8, ["modelValue", "file_ids"]),
    _createVNode(UploadKnowledgeFile, {
      modelValue: showUploadDialog.value,
      "onUpdate:isShow": _cache[18] || (_cache[18] = ($event: any) => (showUploadDialog.value = $event)),
      onRefreshList: refresh_list_tree,
      node_id: cur_node_id.value
    }, null, 8, ["modelValue", "node_id"])
  ], 64))
}
}

})