import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "upload-info" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "button-bottom" }

import {ref} from 'vue'
import type {UploadFile, UploadInstance, UploadProps, UploadUserFile} from 'element-plus'
import {useI18n} from "vue-i18n" // 导入国际化插件
import {formatFileSize} from "@/services/tools"
import {KnowledgeFile} from "@/types/KnowledgeFile";
import {createOrUpdateKnowledgeFile} from "@/services/fileService"

interface Props {
  // 传递的 treeName 必须是字符串
  node_id: number
}

// 使用 defineProps 接收父组件传递的 props

export default /*@__PURE__*/_defineComponent({
  __name: 'UploadKnowledgeFile',
  props: {
    node_id: {}
  },
  emits: ["refreshList", "update:isShow"],
  setup(__props: any, { emit: __emit }) {

const uploadDialog = ref<boolean>(false)
const errorMessage = ref<string>('')
const fileList = ref<UploadUserFile[]>([])
const uploadProgress = ref<number>(0)
const uploadFileRef = ref<UploadInstance>()
const disableUpButton = ref<boolean>(true)
const disablelUpload = ref<boolean>(false)
// 使用国际化功能
const {t} = useI18n()

// 定义 trans
const props = __props;
const emit = __emit // 定义 emits


const refreshFatherList = () => {
  try {
    emit("refreshList") // 触发 refreshList 事件
  } catch (error) {
    console.error('Error refreshing father list:', error) // 打印错误信息
  }
}
// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    uploadDialog.value = false // 隐藏对话框
    fileList.value.length = 0
    uploadFileRef.value?.clearFiles()
    uploadProgress.value = 0
    disableUpButton.value = true
    errorMessage.value = ""
    refreshFatherList()
    emit('update:isShow', false) // 触发 update:isShow 事件
  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
}
const clearFiles = () => {
  fileList.value.length = 0
  uploadFileRef.value?.clearFiles()
  disableUpButton.value = true
}
const removeFile = (file: UploadUserFile) => {
  const index = fileList.value.indexOf(file)
  // 获取上传文件并过滤出合法文件
  let temp_files = file as UploadFile
  if (index !== -1) {
    uploadFileRef.value?.handleRemove(temp_files)
    fileList.value.splice(index, 1)
  }
  if (fileList.value.length == 0) {
    disableUpButton.value = true
  }
}
const handleChange: UploadProps['onChange'] = (uploadFile, uploadFiles) => {
  // 去除重复的文件，文件名和大小都一样的文件视为重复
  const uniqueFiles = uploadFiles.reduce((acc, currentFile) => {
    // 判断文件名和大小是否已经存在
    const exists = acc.some(file => file.name === currentFile.name && file.size === currentFile.size);
    // 如果文件不存在，则添加到数组中
    if (!exists) {
      acc.push(currentFile);
    }
    return acc;
  }, [] as typeof uploadFiles);
  fileList.value.length = 0
  uploadProgress.value = 0
  fileList.value = uniqueFiles
  disableUpButton.value = fileList.value.length <= 0;
}

const saveFile = async () => {
  disableUpButton.value = true
  disablelUpload.value = true
  try {
    const temp_file: KnowledgeFile = {
      node_id: props.node_id,
      name: "empty",
      size: 0,
      convert_text: false,
      convert_vectorization: false
    };

    // 获取上传文件并过滤出合法文件
    let up_files: File[] = fileList.value
        .filter(file => file.raw instanceof File)
        .map(file => file.raw as File);

    // 计算总文件大小
    let totalFileSize = up_files.reduce((acc, file) => acc + file.size, 0);
    let uploadedFileSize = 0; // 已上传文件大小

    // 更新进度的方法
    const _updateProgress = () => {
      let progress = Math.round((uploadedFileSize / totalFileSize) * 100);
      if (progress > 100) {
        progress = 100
      }
      uploadProgress.value = progress
    };

    const _uploadInBatches = async (files: File[]) => {
      const MAX_BATCH_SIZE = 50 * 1024 * 1024; // 每个批次最大50MB
      const uploadTasks: (() => Promise<any>)[] = [];
      let currentBatch: File[] = [];
      let currentBatchSize = 0;

      // 遍历所有文件
      for (const file of files) {
        const fileSize = file.size;
        // 如果文件大小超过50MB，则单独作为一个批次上传
        if (fileSize > MAX_BATCH_SIZE) {
          // 先将当前批次加入任务队列（如果当前批次有文件）
          if (currentBatch.length > 0) {
            // 捕获当前批次和当前批次大小
            const batchToUpload = [...currentBatch];
            const batchSize = currentBatchSize;
            uploadTasks.push(async () => {
              await createOrUpdateKnowledgeFile(temp_file, batchToUpload);
              uploadedFileSize += batchSize; // 使用捕获的 batchSize
              _updateProgress(); // 更新进度
            });
            currentBatch = [];  // 清空当前批次
            currentBatchSize = 0; // 重置当前批次大小
          }
          // 大文件单独作为一个批次
          uploadTasks.push(async () => {
            await createOrUpdateKnowledgeFile(temp_file, [file]);
            uploadedFileSize += fileSize; // 累加单个大文件的大小
            _updateProgress(); // 更新进度
          });

        } else {
          // 当前文件大小加到当前批次
          if (currentBatchSize + fileSize <= MAX_BATCH_SIZE) {
            currentBatch.push(file);
            currentBatchSize += fileSize;
          } else {
            // 捕获当前批次和大小，然后重置它们
            const batchToUpload = [...currentBatch];
            const batchSize = currentBatchSize;
            uploadTasks.push(async () => {
              await createOrUpdateKnowledgeFile(temp_file, batchToUpload);
              uploadedFileSize += batchSize; // 使用捕获的 batchSize
              _updateProgress(); // 更新进度
            });

            // 开始新的批次
            currentBatch = [file];
            currentBatchSize = fileSize;
          }
        }
      }
      // 上传最后一批（如果有剩余文件）
      if (currentBatch.length > 0) {
        // 捕获最后的批次和大小
        const batchToUpload = [...currentBatch];
        const batchSize = currentBatchSize;
        uploadTasks.push(async () => {
          await createOrUpdateKnowledgeFile(temp_file, batchToUpload);
          uploadedFileSize += batchSize; // 使用捕获的 batchSize
          _updateProgress(); // 更新进度
        });
      }

      // 控制并发批次上传，限制同时处理的批次数量为3个（可以根据需求调整）
      await limitConcurrentPromises(uploadTasks, 3); // 限制同时最多3个批次上传
    };


    await _uploadInBatches(up_files);
    // await closeDialog();

  } catch (error) {
    console.log(error);
  } finally {
    fileList.value.length = 0
    uploadFileRef.value?.clearFiles()
    disablelUpload.value = false
  }
};

const limitConcurrentPromises = async <T>(tasks: (() => Promise<T>)[], limit: number): Promise<T[]> => {
  const results: Promise<T>[] = [];
  const executing = new Set<Promise<T>>();

  for (const task of tasks) {
    const promise = task().then((result: T) => {  // 显式声明 result 的类型
      executing.delete(promise);
      return result;
    });

    executing.add(promise);

    if (executing.size >= limit) {
      await Promise.race(executing);  // 等待最早完成的任务
    }

    results.push(promise);
  }

  return Promise.all(results);  // 返回所有结果
};



return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    visible: uploadDialog.value,
    onClose: closeDialog,
    title: _ctx.$t('knowledge.move-title'),
    width: "800px",
    "align-center": "",
    center: "",
    draggable: "",
    "close-on-click-modal": false
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_button, {
          disabled: disableUpButton.value,
          type: "primary",
          onClick: saveFile
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "check",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.upload')), 1)
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_el_button, {
          disabled: disableUpButton.value,
          type: "danger",
          onClick: clearFiles,
          plain: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "trash-can",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.clear')), 1)
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: closeDialog,
          plain: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "xmark",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.close')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_upload, {
        ref_key: "uploadFileRef",
        ref: uploadFileRef,
        drag: "",
        "show-file-list": false,
        "auto-upload": false,
        limit: 1000,
        multiple: "",
        "on-change": handleChange,
        disabled: disablelUpload.value
      }, {
        trigger: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            icon: "upload",
            bounce: true,
            size: "2xl"
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_text, { tag: "b" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('knowledge.upload-drop')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_text, {
              type: "primary",
              tag: "b"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('knowledge.upload-link')), 1)
              ]),
              _: 1
            }),
            _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
            _createVNode(_component_el_text, { size: "small" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('knowledge.upload-tips')), 1)
              ]),
              _: 1
            }),
            _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
            _createVNode(_component_el_text, {
              type: "warning",
              size: "small",
              tag: "i"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('knowledge.upload-detail')), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["disabled"]),
      (uploadProgress.value > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_el_progress, {
              "stroke-width": 18,
              striped: "",
              "striped-flow": "",
              percentage: uploadProgress.value,
              class: "progress"
            }, null, 8, ["percentage"])
          ]))
        : _createCommentVNode("", true),
      (!disableUpButton.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_el_table, {
              data: fileList.value,
              height: "400",
              stripe: "",
              class: "upload-table"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  prop: "name",
                  label: _ctx.$t('knowledge.file-name'),
                  sortable: ""
                }, null, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  prop: "size",
                  label: _ctx.$t('knowledge.file-size'),
                  width: "80",
                  align: "center",
                  sortable: ""
                }, {
                  default: _withCtx(({ row }) => [
                    _createTextVNode(_toDisplayString(_unref(formatFileSize)(row.size)), 1)
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_el_table_column, {
                  fixed: "right",
                  label: _ctx.$t('message.action'),
                  width: "90",
                  align: "center"
                }, {
                  default: _withCtx(({ row }) => [
                    _createVNode(_component_el_button, {
                      type: "danger",
                      size: "small",
                      onClick: ($event: any) => (removeFile(row)),
                      circle: "",
                      plain: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_font_awesome_icon, { icon: "trash-can" })
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["data"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}
}

})