import axios from '@/setups/axios-setup'

import config from '@/services/config'
import {KnowledgeFile} from "@/types/KnowledgeFile";


export const getKnowledgeFiles = async (search_data: any | null, node_id: number, page: number, pagesize: number) => {
    // 构建请求数据对象
    const data = {
        search_keyword: search_data.keyword, // 搜索关键字
        all: search_data.all,
        text: search_data.text,
        vectorization: search_data.vectorization,
        page: page,                // 当前页码
        page_size: pagesize,       // 每页项目数量
        status: node_id            // 树节点ID
    };
    const response = await axios.post(`${config.apiFileUrl}/knowledge_files`, data);
    return response.data;
};

export const getKnowledgeFileById = async (fileId: number) => {
    const response = await axios.get(`${config.apiFileUrl}/knowledge_file/${fileId}`);
    return response.data;
};

export const createOrUpdateKnowledgeFile = async (knowledge_file: KnowledgeFile, upload_files: File[] | null) => {
    // 使用FormData构建请求数据
    const formData = new FormData();
    formData.append('file_data', JSON.stringify(knowledge_file)); // 添加文件数据，添加一个就好

    // 将每个文件添加到FormData对象
    if (upload_files) {
        upload_files.forEach((file, index) => {
            formData.append('files', file);
        });
    } else {
        formData.append('files', new Blob());
    }

    const response = await axios.post(`${config.apiFileUrl}/save_knowledge_files`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};

export const deleteKnowledgeFiles = async (ids: number[]) => {
    if (ids.length == 0) return null;
    const response = await axios.delete(`${config.apiFileUrl}/delete_knowledge_files`, {data: ids});
    return response.data;
};

export const moveKnowledgeFiles = async (node_id: number, file_ids: number[]) => {
    if (file_ids.length == 0 || node_id == null) return null;
    // 构建请求数据对象
    const data = {
        node_id: node_id, // 树节点ID
        file_ids: file_ids // 文件ID列表
    };
    const response = await axios.post(`${config.apiFileUrl}/move_knowledge_files`, data);
    return response.data;
}